;(function ($, window, document) {
    'use strict';
    var app = {
        scope : {
            productSwiper : [],
            productSwiperClone : []
        },
        initEnquire: function () {
            enquire.register('screen and (max-width:479px)', {
                match: function () {
                   
                },
                unmatch: function () {
                   
                }
            });
            enquire.register('screen and (min-width:480px) and (max-width:767px)', {
                match: function () {    
                   
                },
                unmatch: function () {
                      
                   
                }
            });                      
            enquire.register('screen and (min-width:768px) and (max-width:991px)', {
                match: function () { 
                    
                },
                unmatch: function () {
        
                }
            });
            enquire.register('screen and (min-width:992px) and (max-width:1024px)', {
                match: function () {
                 
                },
                unmatch: function () {
       
                    
                }
            });
            enquire.register('screen and (min-width:1025px)', {
                match: function () {
               
                },
                unmatch: function () {
              
                }
            });
        },
        initDetectMobile: function () {
            var mobileDevice = false,
                mobileMaxWidth = 767;

            if (($(window).width() <= mobileMaxWidth) || (($(window).width() <= mobileMaxWidth) && (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || navigator.userAgent.match(/ZuneWP7/i) || navigator.userAgent.match(/SymbianOS/i) || navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Windows Mobile/i)))) {
                mobileDevice = true;
            }

            return mobileDevice;
        },
        
        initDetectTablet: function () {
            var tabletDevice = false,
                tabletMinWidth = 768,
                tabletMaxWidth = 1024;

            if (($(window).width() >= tabletMinWidth && $(window).width() <= tabletMaxWidth) && (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) || navigator.userAgent.match(/ZuneWP7/i) || navigator.userAgent.match(/SymbianOS/i) || navigator.userAgent.match(/Mobile/i) || navigator.userAgent.match(/Windows Mobile/i))) {
                tabletDevice = true;
            }
            return tabletDevice;
        },
        
        initMatchHeight: function(){
            $('.match-height').matchHeight('byRow');
        },        

        initMatchHeightMedium: function(){
            $('.match-height-medium').matchHeight('byRow');
        },
        
        initMatchHeightDesktop: function(){
            $('.match-height-desktop').matchHeight('byRow');
        }, 
        
        initSelectLang: function(){
            $('.l-header__lang-item--active').find('.l-header__lang-link').click(function(e){
                e.preventDefault();
                $('.l-header__lang-item').toggleClass('l-header__lang-item--opened');
                $('.l-header__lang-list').toggleClass('l-header__lang-list--opened');
            });
        },
        
        initScrollSection: function(){
            $('.scroll-section').on('click', function(e){
                e.preventDefault();                
                var href = $(this).attr('href');
                $('html, body').animate({
                    scrollTop: $(href).offset().top - 20
                }, 500); 
            });
        },
        
        initSlider: function(){
   
            var sliderSelector = window.sliderSelector || $(".bxslider");
            if (sliderSelector.hasOwnProperty("destroySlider")) {
                sliderSelector.destroySlider();
            }
            
            var options = {
                captions: false,
                auto: true,
                infiniteLoop: true,
                controls: true,
                pager: false,
                minSlides: 1,
                maxSlides: 3,
                slideMargin: 30,
                slideWidth: 475,
                nextText: '<i class="fa fa-angle-right"></i>',
                prevText: '<i class="fa fa-angle-left"></i>',
                pause: 5000,
            };   

            sliderSelector.bxSlider(options);         
        }, 
        
        reloadOnOrientationChange: function(){
            $(window).on('orientationchange', function() {
                this.location.reload(false);
            });
        },

        initShareFunctionDesktop: function(){           
            $('body').on('mouseover', '.m-share__item', function(){
                $(this).find('.m-share__button').addClass('m-share__button--hide');
                $(this).find('.m-share__list').addClass('m-share__list--show');
            });
            $('body').on('mouseleave', '.m-share__item', function(){
                $(this).find('.m-share__button').removeClass('m-share__button--hide');
                $(this).find('.m-share__list').removeClass('m-share__list--show');
            });
        },
        
        destroyShareFunctionDesktop: function(){
            $('.m-share__item').off('mouseover').off('mouseleave');
        },
        
        initShareFunctionMobile: function(){
            app.destroyShareFunctionDesktop();
            
            $('body').on('click', '.swiper-button-next, .swiper-button-prev', function(){
                $('.m-share__button').removeClass('m-share__button--active');
                $('.m-share__list').removeClass('m-share__list--show');
                $('.swiper-share').removeClass('swiper-share--active');
                $('.swiper-container').removeClass('swiper-container--active').removeAttr('style');
                $('.swiper-button-prev').removeClass('swiper-button-prev--active');
                $('.swiper-button-next').removeClass('swiper-button-next--active');
                $('.m-share__label').css('display', 'block');
            });
            
            $('body').on('click', '.m-share__item', function(){

                $(this).find('.m-share__button').toggleClass('m-share__button--active');
                $(this).find('.m-share__list').toggleClass('m-share__list--show');
                $(this).closest('.swiper-share').toggleClass('swiper-share--active');
                $(this).closest('.swiper-container').toggleClass('swiper-container--active');
                $(this).closest('.swiper-container').find('.swiper-button-prev').toggleClass('swiper-button-prev--active');
                $(this).closest('.swiper-container').find('.swiper-button-next').toggleClass('swiper-button-next--active');
                $(this).parent('.m-share__icons').parent('.m-share').find('.m-share__label').toggle();
              
            });         
        },
        
        initRelocateIntro: function(){
            var intro = $.extend(true, {}, $('.m-intro__text'));
            $('.m-intro').append(intro);
        },
        
        initUnrelocateIntro: function(){
            var intro = $.extend(true, {}, $('.m-intro__text'));
            $('.m-intro h1').after(intro);
        },

        initSwiper: function() {
            /*jshint ignore:start*/
            
            var mySwiper = new Swiper ('.swiper-container', {
                loop: true,
                slidesPerView: 2,
                loopAdditionalSlides: 3,
                spaceBetween: 235,
                keyboardControl: true,
                watchSlidesProgress: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                centeredSlides: true,
                slidesPerColumn: 1,
                effect: 'coverflow',
                coverflow: {
                    rotate: 0,
                    stretch: -50,
                    depth: 800,
                    modifier: 1,
                    slideShadows : false
                },
                breakpoints: {
                    1259: {
                        spaceBetween: 180
                    },
                    991: {
                        spaceBetween: 130
                    }, 
                    767: {
                        spaceBetween: 0,
                        slidesPerView: 1,
                        loopAdditionalSlides: 1,
                        stretch: 0,
                        depth: 0
                    }
                }
            });  
            /*jshint ignore:end*/
        },
        initTooltip: function() {
            $('[data-toggle="tooltip"]').tooltip();
        },
        
        initSelectPicker: function() { 
            $('.selectpicker').selectpicker({
                style: 'btn-default',
                size: 2 
            });  
        },
        
        initMobileMenu: function() {
            $('.m-nav__button').on('click', function() {
                $('.m-nav__icon').toggleClass('m-nav__icon--opened');
                $('.m-nav__list').toggle().toggleClass('m-nav__list--opened'); 
                if ($('.m-nav__list').hasClass('m-nav__list--opened')) {
                    $('.m-nav__list').css('height', $(window).innerHeight());
                } else {
                     $('.m-nav__list').css('height', 0);
                }
            });
        },

        initDatepicker: function () {
            $('.datepicker').datepicker();
        }, 
        
        initCheckboxToggle: function () {
            var checkboxesCount = 0,
                checkboxesUnchecked = 0,
                checkboxesChecked = 0;
            $('.m-toggle__label').click(function() {
                if ($(this).closest('.checkbox').find('input[type="checkbox"]').is(':checked')) {
                    $(this).closest('.m-toggle').find('.m-toggle__content').first().hide();
                } else {
                    $(this).closest('.m-toggle').find('.m-toggle__content').first().show();
                }
            });
            $('.checkbox--single label').click(function() {
               if ($(this).closest('.m-form').find('.checkbox--all input[type="checkbox"]').is(':checked')) {
                   $(this).closest('.m-form').find('.checkbox--all input[type="checkbox"]').attr('checked', false);
                   $(this).closest('.m-form').find('.checkbox--all').find('.m-toggle__content').first().hide();
                   $(this).closest('.m-form').find('.m-checkbox-bottom__checkbox .checkbox--all label').addClass('checkbox-content-hide'); 
               }
               
               if ($(this).closest('.checkbox--single').find('input[type="checkbox"]').is(':checked')) {
                   console.log('1');
                   //$(this).closest('.checkbox--single').find('input[type="checkbox"]').removeAttr('checked', 'checked');
                   
               } else {
                    //$(this).closest('.checkbox--single').find('input[type="checkbox"]').removeAttr('checked', 'checked');
                    console.log('2');
               }
            });
            $('.checkbox--all label').click(function() {
               if ($(this).closest('.m-form').find('.checkbox--single input[type="checkbox"]').is(':checked')) {
                   $(this).closest('.m-form').find('.checkbox--single input[type="checkbox"]').attr('checked', false);
                   $(this).closest('.m-form').find('.checkbox--single').closest('.m-toggle').find('.m-toggle__content').hide();
               }
               $(this).closest('.m-form').find('.m-checkbox-bottom__checkbox .checkbox--single label').addClass('checkbox-content-hide'); 
            });
          
            $('.m-form').find('.m-checkbox-bottom__checkbox .checkbox label').addClass('checkbox-content-hide'); 
                
            $('.m-form').find('.m-checkbox-bottom__checkbox .checkbox label').click(function() {
                $(this).toggleClass('checkbox-content-hide');
                //show content
                $(this).closest('.m-form').find('.m-checkbox-bottom__content').show(); 
                
                //get an amount of all checkboxes
                checkboxesCount = $(this).closest('.m-form').find('.m-checkbox-bottom__checkbox .checkbox label').length;
                //get an amount of unchecked checkboxes
                checkboxesUnchecked = $(this).closest('.m-form').find('.m-checkbox-bottom__checkbox .checkbox .checkbox-content-hide').length;
                checkboxesChecked = checkboxesCount - checkboxesUnchecked;
                
                //check if all avaliable checkboxes are unchecked
                if(checkboxesCount === checkboxesUnchecked) {
                   $(this).closest('.m-form').find('.m-checkbox-bottom__content').hide();   
                }
                
                //make selects enable when only one checkbox is clicked and it's not 'all' checkbox
                if ((checkboxesChecked === 1) && ($(this).closest('.m-form').find('.checkbox--all .m-toggle__label').hasClass('checkbox-content-hide'))) {
                    $('.m-toggle__label-child--select').removeClass('disabled');
                    $('.select-custom').removeClass('disabled');
                    $('select').attr('disabled', false);
                } else {
                    $('.m-toggle__label-child--select').addClass('disabled');
                    $('.select-custom').addClass('disabled');
                    $('select').attr('disabled', true);
                }
            }); 
               
            //show info when KFZ checkbox is clicked
            $('.m-checkbox-bottom__checkbox--special .checkbox label').click(function() {
                $('.m-checkbox-bottom__content--special').toggle();
            });
            
            //open all checkboxes (special checkbox)
            $('.checkbox--open-all .m-toggle__label').click(function() {
                if ($(this).closest('.checkbox').find('input[type="checkbox"]').is(':checked') === false) {
                    $(this).closest('.m-form').find('.checkbox--single input[type="checkbox"]').prop('checked', true);
                    $(this).closest('.m-form').find('.checkbox--single').closest('.m-toggle').find('.m-toggle__content').show();                   
                } else {
                    $(this).closest('.m-form').find('.checkbox--single input[type="checkbox"]').attr('checked', false);
                    $(this).closest('.m-form').find('.checkbox--single').closest('.m-toggle').find('.m-toggle__content').hide();   
                }
            });
        
        },

        initFileUpload: function() {
            if (document.getElementById("uploadBtn")) {
                document.getElementById("uploadBtn").onchange = function () {
                    document.getElementById("uploadFile").value = this.value;
                };
            }
            
        },
        
        initDropdown: function () {
            var category,
                categoryLabel = /^sparte:/i,
                boxHeading;
            for (var i = 0, max = $('.m-box__dropdown-content').length; i < max; i++ ) {
                if (($($('.m-box__dropdown-content')[i]).hasClass('active')) === false) {
                    ($($('.m-box__dropdown-content')[i])).closest('.m-box').find('.m-box__dropdown-content').hide();
                } 
              
            }
                            
            $('.m-box__dropdown-button').on('click', function() {
                boxHeading = $(this).closest('.m-box').find('.m-box__heading').first();
                
                $(this).toggleClass('active');
                $(this).closest('.m-box__header').next().toggle('fast').toggleClass('active');
                
                if ($(this).hasClass('m-box__dropdown-button--child') === false)  {
                    if ($(this).hasClass('active') === false) {
                        category = boxHeading.html();
                        if (categoryLabel.test(category)) { //check if in header is text "Sparte:
                            boxHeading.html(category.slice(8));
                        }               
                    } else {
                        category = boxHeading.html();
                        boxHeading.html('Sparte: ' + category);
                    }
                } 
            });
            
        }
    };
 
    $(document).ready(function () {
        app.initEnquire();
        app.initMatchHeight();
        app.initTooltip();
        app.initMobileMenu();
        app.initSelectPicker();
        app.initDatepicker();
        app.initCheckboxToggle();
        app.initFileUpload();
        app.initDropdown();
    });

    $(window).load(function () {
        
    });
 
    $(window).resize(function () {

    });

    $(window).scroll(function () {

    });        
})(jQuery, window, document);

